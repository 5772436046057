<template>
  <div class="registerForm_container">
    <div id="register-card" class="home-card">
      <v-row no-gutters>
        <v-col cols="12" md="6" sm="6">
          <h1>Sign Up</h1>
          <v-form
            ref="form"
            v-model="valid"
            class="registerForm"
            lazy-validation
            @submit.prevent="register"
          >
            <v-text-field
              v-model.trim="name"
              :rules="requiredRule"
              class="mb-1"
              label="Name"
              outlined
              dense
              placeholder="Your Name"
              type="text"
              prepend-inner-icon="mdi-account"
              autofocus
            >
            </v-text-field>
            <v-text-field
              v-model.trim="email"
              :rules="emailRules"
              class="mb-1"
              label="Email"
              outlined
              dense
              placeholder=" "
              type="email"
              prepend-inner-icon="mdi-email"
            >
            </v-text-field>
            <v-text-field
              v-model.trim="password"
              :rules="passwordRules"
              label="Password"
              outlined
              dense
              placeholder=" "
              type="password"
              prepend-inner-icon="mdi-lock"
            >
            </v-text-field>
            <v-text-field
              v-model.trim="repeat_password"
              :rules="repeatPasswordRules"
              label="Repeat your password"
              outlined
              dense
              placeholder=" "
              type="password"
              prepend-inner-icon="mdi-lock-outline"
            >
            </v-text-field>
            <v-text-field
              v-model.trim="contact_number"
              :rules="requiredRule"
              label="Your Contact Number"
              outlined
              dense
              placeholder=" "
              type="phone"
              prepend-inner-icon="mdi-cellphone"
            >
            </v-text-field>

            <v-select
              v-model="role_id"
              :items="role_items"
              item-text="role_name"
              item-value="id"
              label="Who Am I"
              :rules="requiredRule"
              @change="change_role"
              style="z-index: 9999"
            ></v-select>

            <div v-if="role_id !== ''">
              <v-text-field
                v-model.trim="entity_name"
                :rules="requiredRule"
                :label="entity_name_label"
                outlined
                dense
                :placeholder="entity_name_label"
                type="text"
                prepend-inner-icon="mdi-town-hall"
              >
              </v-text-field>

              <v-text-field
                v-model.trim="entity_location"
                :rules="requiredRule"
                :label="entity_location_label"
                outlined
                dense
                :placeholder="entity_location_label"
                type="text"
                prepend-inner-icon="mdi-map-marker-outline"
              >
              </v-text-field>

              <v-select
                v-model="education_level"
                :items="education_level_items"
                :label="education_level_label"
                :rules="requiredRule"
              ></v-select>
              <v-textarea
                v-if="role_id === 4"
                v-model.trim="looking_for"
                :rules="requiredRule"
                label="What are you looking for?"
                outlined
                dense
                placeholder="What are you looking for?"
                type="text"
                prepend-inner-icon="mdi-store-search-outline"
              >
              </v-textarea>
            </div>

            <div class="remember_forgot_parent">
              <v-checkbox
                v-model="agreed"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                :label="`I agree all statements in Terms of service`"
              ></v-checkbox>
            </div>

            <div class="loginBtn">
              <v-btn
                :disabled="!valid"
                type="submit"
                class="button is-dark"
                name="button"
              >
                Register
              </v-btn>
            </div>
          </v-form>
        </v-col>
        <v-col class="text-center" cols="12" md="6" sm="6" align-self="center">
          <img alt="Logo" src="../assets/images/signup-image.jpg" />
          <p class="mt-11">
            <strong>
              <a href="javascript:void(0);" @click="loginPage()">Login</a>
              <br />
              <a :href="website">Take me back</a>
            </strong>
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  ACTION_REGISTER,
  GET_ROLES,
  GETTER_ROLES
} from "../store/modules/auth";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => {
    return {
      website: process.env.VUE_APP_WEBSITE,
      name: "",
      contact_number: "",
      agreed: false,
      valid: false,
      email: "",
      password: "",
      repeat_password: "",
      role_id: "",
      role_items: [],
      entity_name: "",
      entity_name_label: "",
      entity_location: "",
      entity_location_label: "Location",
      education_level: "",
      education_level_label: "Education Level",
      looking_for: null,
      education_level_items: [
        { text: "High School", value: "High School" },
        {
          text: "Undergrad/Bachelor/Diploma Degrees",
          value: "Undergrad/Bachelor/Diploma Degrees"
        },
        { text: "Masters", value: "Masters" },
        { text: "PhD", value: "PhD" }
      ],
      error: null
    };
  },
  async mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        document.getElementById("register-card").click();
      }
    };
    await this.get_roles();
    this.role_items = this.getter_roles.filter(
      (it) => it.role_name.toLowerCase() !== "admin"
    );
  },
  computed: {
    ...mapGetters("auth", {
      getter_roles: GETTER_ROLES
    }),
    requiredRule() {
      return [(v) => !!v || "This field is required."];
    },
    emailRules() {
      return [
        (value) => (!!value && !!value.trim()) || "Email is required.",
        (value) => {
          const pattern =
            /^(([^<>()#$%&*{}~=[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      ];
    },
    passwordRules() {
      return [
        ((v) => !!v || "Password is required.",
        (v) => (v && !/^\s*$/.test(v)) || "Only spaces are not allowed")
      ];
    },
    repeatPasswordRules() {
      return [
        (v) => !!v || "Repeat Password is required.",
        (v) =>
          v === this.password || "The password confirmation does not match."
      ];
    }
  },
  methods: {
    ...mapActions("auth", {
      action_register: ACTION_REGISTER,
      get_roles: GET_ROLES
    }),
    loginPage() {
      this.$router.push("/");
    },
    async register() {
      if (this.$refs.form.validate()) {
        let first_name = this.name.substring(0, this.name.indexOf(" "));
        let last_name = this.name.substring(this.name.indexOf(" ") + 1);
        if (!first_name) {
          first_name = last_name;
          last_name = null;
        }
        await this.action_register({
          first_name: first_name,
          last_name: last_name,
          email: this.email,
          password: this.password,
          contact_number: this.contact_number,
          agreed: this.agreed,
          role_id: this.role_id,
          looking_for: this.looking_for,
          entity_name: this.entity_name,
          entity_location: this.entity_location,
          education_level: this.education_level
        });
      }
    },
    change_role(role_id) {
      console.log("role_id", role_id);
      this.entity_name = "";
      this.entity_location = "";
      this.education_level = "";
      //change location and label if required
      if (role_id === 4) {
        this.entity_name_label = "Education Institution";
        //this.entity_location_label = "Location";
        //this.education_level_label = "Education Level";
      } else if (role_id === 5) {
        this.entity_name_label = "Public Sector Entity Name";
        //this.entity_location_label = "Location";
        //this.education_level_label = "Education Level";
      } else if (role_id === 6) {
        this.entity_name_label = "Company Name";
        //this.entity_location_label = "Location";
        //this.education_level_label = "Education Level";
      } else if (role_id === 7) {
        this.entity_name_label = "Education Institution";
        //this.entity_location_label = "Location";
        //this.education_level_label = "Education Level";
      } else if (role_id === 8) {
        this.entity_name_label = "Education Institution";
        //this.entity_location_label = "Location";
        //this.education_level_label = "Education Level";
      }
    }
  }
};
</script>

<style lang="scss">
.registerForm_container {
  position: relative;
  display: block;
  min-width: 100vw;
  min-height: 100vh;
  margin-top: -68px !important;

  .home-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    width: 900px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;
    padding-top: 65px;
    padding-bottom: 40px;
    padding-left: 55px;

    h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 35px;
      color: #000;
      margin-bottom: 40px;
      margin-left: 25px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      margin-top: 15px;

      a {
        color: #23bdcb;
      }
    }

    .socialMedia_login {
      ul {
        display: block;
        padding-left: 0;
        list-style: none;
        margin-top: 25px;
        margin-left: 25px;

        li {
          display: inline-block;
          list-style: none;
          padding: 5px;
          vertical-align: middle;

          a {
            text-decoration: none;
          }
        }
      }
    }

    .registerForm {
      max-width: 300px;
      margin: 25px 0px 25px 25px;

      .v-input {
        fieldset {
          color: #999;
          background: #ffffff;
          //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
          border-radius: 0px;
          border: none;
          border-bottom: 1px solid #000;
        }

        label {
          color: #999;
          font-size: 14px;
        }

        .v-input__prepend-inner {
          z-index: 99;
          transform: translate(-10px, 0px);
        }

        .v-icon {
          font-size: 16px;
          color: #000;
        }
      }

      .remember_forgot_parent {
        .v-input--checkbox {
          margin-top: 0;
          padding-top: 0;

          .v-icon {
            color: #cdcdcd;
          }

          label {
            color: #2c2c2c;
            font-size: 13px;
          }
        }

        a {
          color: #23bdcb;
          font-size: 13px;
        }
      }

      .loginBtn {
        margin-top: 1.5rem;
        text-align: left;

        button {
          background: #6dabe4;
          border-radius: 4px;
          min-width: 140px;
          min-height: 50px;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          box-shadow: none !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.input {
  border: 2px solid #ffb581;
}

.input:focus,
.button:focus {
  border-color: #ffa95e;
  box-shadow: 0 0 0 0.125em rgba(220, 138, 50, 0.25);
}

.error {
  color: #ff4a4a;
}

.v-menu__content {
  .v-list-item__content {
    .v-list-item__title {
      color: rgba(0, 0, 0, 0.87);
      font-family: "Raleway", serif;
      font-size: 14px;
      margin: 0px 0;
      font-weight: 400;
    }
  }
}

/* ------------For mobile devices------------- */

@media (max-width: 991px) {
  .registerForm_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin-top: 0 !important;

    .home-card {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      padding-top: 25px;
      text-align: center;
      padding-left: 25px;

      > div:nth-child(1) {
        flex-direction: column-reverse;
      }

      h1 {
        margin-top: 15px;
        margin-left: 0;
      }

      .registerForm {
        min-width: 100%;
        margin-left: 0;
        margin-bottom: 40px;

        .loginBtn {
          text-align: center;
        }
      }

      .bottomSection_left {
        p {
          text-align: center;
          margin-bottom: 0;
        }
      }

      .socialMedia_login {
        ul {
          margin-top: 0;
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }
}
@media (max-height: 750px) {
  .registerForm_container {
    .home-card {
      position: relative;
      top: 0;
      transform: none;
      left: 0;
      margin: 20px auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .registerForm_container {
    .home-card {
      > div:nth-child(1) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
</style>
