<template>
  <div class="home-container">
    <div class="loader" v-if="isLoading"></div>
    <register-card />
  </div>
</template>

<script>
import RegisterCard from "../components/RegisterCard.vue";
export default {
  data() {
    return {
      isLoading: false
    };
  },
  components: { RegisterCard },
  computed: {}
};
</script>

<style scoped>
/* ------------For mobile devices------------- */

@media (max-width: 480px) {
}
</style>
